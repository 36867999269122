import template from '../templates/components/categories.html';

var menuOpen = false;

export const categories = function(notifier, language, api) {
  return {
    template,
    data() {
        const imageIndex = Math.floor(Math.random() * 14) + 1;

        return {
          categories: [],
          mobileTitle: language.welcomeMobile,
          title: language.welcome,
          subTitle: language.welcomeSub,
          selectedCategory: undefined,
          imageIndex: imageIndex,
          imageClass: 'signin-image-inner',
          imageSrc: './images/heroimages/Image' + imageIndex + '.jpg'
        };
      },
      created() {
        notifier.addListener('app:menu-state', this.changeMenuState);
        notifier.addListener('app:initial-data', this.setCategories);
        notifier.addListener('app:reset', this.reset);
      },
      destroyed() {
        notifier.removeListener('app:menu-state', this.changeMenuState);
        notifier.removeListener('app:initial-data', this.setCategories);
        notifier.removeListener('app:reset', this.reset);
      },
      methods: {
        reset(){
          this.selectedCategory = undefined;
        },
        setCategories(data) {
          this.categories = api.getTopCategories(data[0]);
        },
        selectCategory(category) {
          if (menuOpen) {
            return;
          }
          this.selectedCategory = category;

          notifier.emit('categories:select', category);
        },
        changeMenuState(state) {
          menuOpen = state;
        }
      }
  };
};
