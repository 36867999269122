import template from '../templates/components/content.html';
import {compose, map, filter, chain, forEach, curry, prop, repeat, splitEvery, flatten} from 'ramda';
import $ from 'jquery';

var menuOpen = false;

export const content = function(notifier, language, api){
    return {
        template,
        data() {
            return {
                showPdfViewer: false,
                backId: '',
                pdfUrl: '',
                contentShown: false,
                gettingContent: false,
                videos: [],
                documents: [],
                currentVideoUrl: undefined,
                videosTitle: language.videos,
                documentsTitle: language.documents,
                discoverTitle: language.discover,
                noVideosTitle: language.noVideos,
                noDocumentsTitle: language.noDocuments,
                viewTitle: language.view,
                viewsTitle: language.views,
                showWatermark: (api.getUser().region === 'NA'),
                resizeOn: true,
                currentVideoCount: $(window).width() >= 1024 ? 4 : 2
            };
        },
        created(){
            notifier.addListener('subcategories:select', this.categorySelected);
            notifier.addListener('content:search', this.doSearch);
            notifier.addListener('content:pick', this.pickSet);
            notifier.addListener('app:menu-state', this.changeMenuState);
            notifier.addListener('app:reset', this.reset);
            $(window).on('resize', this.resize);
            $(document).on('webkitfullscreenchange mozfullscreenchange fullscreenchange MSFullscreenChange', this.fullscreenChange);
            $('video').off('webkitbeginfullscreen webkitendfullscreen', this.fullScreenChange);
        },
        destroyed(){
            notifier.removeListener('subcategories:select', this.categorySelected);
            notifier.removeListener('content:search', this.doSearch);
            notifier.removeListener('content:pick', this.pickSet);
            notifier.removeListener('app:menu-state', this.changeMenuState);
            notifier.removeListener('app:reset', this.reset);
            $(window).off('resize', this.resize);
            $(document).off('webkitfullscreenchange mozfullscreenchange fullscreenchange MSFullscreenChange', this.fullscreenChange);
            $('video').off('webkitbeginfullscreen webkitendfullscreen', this.fullScreenChange);
        },
        methods: {
           reset() {
               this.videos = [];
               this.documents = [];
               this.contentShown = false;
           },
            categorySelected(category){
                this.videosTitle = language.videosFromCategory + ' - ' + category.name;
                this.documentsTitle = language.documentsFromCategory + ' - ' + category.name;

                notifier.emit('app:toggle-loading');
                this.gettingContent = true;

                api.getContent({
                    type: 'category',
                    id: category.id,
                }).fork(this.contentError, this.setContent);
            },
            doSearch(searchtext){
                this.videosTitle = language.videosFromSearch + ' - ' + searchtext;
                this.documentsTitle = language.documentsFromSearch + ' - ' + searchtext;

                notifier.emit('app:toggle-loading');
                this.gettingContent = true;

                api.getContent({
                  type: 'search',
                  searchtext: searchtext
                }).fork(this.contentError, this.setContent);
            },
            pickSet(type){
                this.videosTitle = language[type] + ' - ' + language.videos;
                this.documentsTitle = language[type] + ' - ' + language.documents;

                notifier.emit('app:toggle-loading');
                this.gettingContent = true;

                api.getContent({
                  type: type
                }).fork(this.contentError, this.setContent);
            },
            contentError(error){
                api.logError(error);
                this.gettingContent = false;
            },
            setContent(content){
                this.gettingContent = false;
                this.contentShown = true;
                this.showPdfViewer = false;
                this.pdfUrl = '';

                this.videos = splitEvery($(window).width() >= 1024 ? 4 : 2)(content.videos);
                this.documents = splitEvery(4)(content.documents);

                notifier.emit('app:toggle-loading');

                this.$nextTick(() => notifier.emit('app:scroll-to', 'content'));
            },
            resize(){
              var newVideoCount = $(window).width() >= 1024 ? 4 : 2;

              if (this.currentVideoCount !== newVideoCount){
                console.log('resize');
                this.currentVideoCount = newVideoCount;
                this.videos = compose(splitEvery(newVideoCount), flatten)(this.videos);
              }
            },
            fullscreenChange(){
              console.log('fullscreen change');

              if (this.resizeOn){
                $(window).off('resize', this.resize);
              } else {
                $(window).on('resize', this.resize);
              }

              this.resizeOn = !this.resizeOn;
            },
            videoSelected(video){
                if (menuOpen){
                    return;
                }

                if (!video.played){
                    api.incrementViewCount(video.id, 'video').fork(api.logError, () => video.viewCount += 1);
                }

                video.played = true;
            },
            changeVideo(event, video){
              var item = $(event.currentTarget).parents('.content-list-item');
              item.find('video').attr('src', video.videoUrl).attr('poster', video.posterUrl);
              item.find('.content-list-item-video-name').text(video.name);
              item.find('.content-list-item-desc').text(video.desc);
              item.find('.content-list-item-time').text(video.timeAgo);
              item.find('.content-list-item-viewcount').text(video.viewCount + ' view' + (video.viewCount === 1 ? '' : 's'));
            },
            documentSelected(event, doc){
                if (menuOpen){
                    return;
                }

                if (!doc.opened){
                  api.incrementViewCount(doc.id, 'document').fork(api.logError, () => doc.viewCount += 1);
                }

                doc.opened = true;

                this.showPdfViewer = true;
                this.pdfUrl = doc.filePath;
                this.pdfTitle = doc.name;
                this.backId = event.currentTarget.id;

                notifier.emit('app:scroll-to', 'pdf-viewer');
            },
            showRelatedVideo(event, video){
              event.preventDefault();

              var related = $(event.currentTarget).parent().siblings('.content-list-item-related');
              var currentImg = $(event.currentTarget).find('img');

              if (currentImg.attr('src').indexOf('related-icon') > -1){
                currentImg.attr('src', './images/minus-icon.png')
                related.slideDown();
              } else {
                currentImg.attr('src', './images/related-icon.png')
                related.slideUp();

                if (video){
                  this.changeVideo(event, video);
                }
              }
            },
            showRelatedDocument(event, doc) {
                event.preventDefault();

                var related = $(event.currentTarget).parent().siblings('.content-list-item-related');
                var currentImg = $(event.currentTarget).find('img');

                if (currentImg.attr('src').indexOf('related-icon') > -1) {
                    currentImg.attr('src', './images/minus-icon.png');
                    related.slideDown();
                } else {
                    currentImg.attr('src', './images/related-icon.png');
                    related.slideUp();
                }
            },
            showTranslatedDocument(event, doc) {
                event.preventDefault();

                var translated = $(event.currentTarget).parent().siblings('.content-list-item-translated');
                var currentImg = $(event.currentTarget).find('img');

                if (currentImg.attr('src').indexOf('Globe-Language-Icon') > -1) {
                    currentImg.attr('src', './images/minus-icon.png');
                    translated.slideDown();
                } else {
                    currentImg.attr('src', './images/Globe-Language-Icon.png');
                    translated.slideUp();
                }
            },
            changeMenuState(state){
                menuOpen = state;
            },
            scrollUp(){
                notifier.emit('app:scroll-to', 'content');
            },
            closePdfViewer(){
              this.showPdfViewer = false;
              notifier.emit('app:scroll-to', this.backId);
            }
        }
    };
};
