import template from '../templates/pages/signin.html';

const imageIndex = Math.floor(Math.random() * 14) + 1;

export const signInPage = function(notifier, language, api) {
  return {
    template,
    data() {
        return {
          userName: '',
          password: '',
          userNameError: '',
          passwordError: '',
          message: '',
          imageIndex: imageIndex,
          imageClass: 'signin-image-inner',
          imageSrc: './images/heroimages/Image' + imageIndex + '.jpg',
          knowledgeBankTitle: language.knowledgeBankTwoLines,
          userNamePlaceholder: language.userName,
          passwordPlaceholder: language.password,
          signInTitle: language.signInTitle,
          havingTroubleQuestion: language.havingTroubleQuestion,
          supportStatement: language.supportStatement,
          signInLabel: language.signIn,
          firstTimeQuestion: language.firstTimeQuestion
        };
      },
      methods: {
        resetPassword(){
          notifier.emit('app:reset-password');
        },
        signIn() {
          this.userNameError = (this.userName === '') ? language.userNameError : '';
          this.passwordError = (this.password === '') ? language.passwordError : '';

          if (this.userName !== '' && this.password !== '') {
            api.signIn({
              emailAddress: this.userName,
              password: this.password
            }).fork(
              api.logError, (details) => {
                if (details.valid){
                    notifier.emit('app:sign-in', details)
                } else {
                  this.message = language.signInError;
                  this.password = '';
                  api.logError('User not authenticated');
                }
              }
            );
          }
        }
      }
  };
};
