
var _dashboard = require('../templates/components/dashboard.html');

var _dashboard2 = _interopRequireDefault(_dashboard);

var _ramda = require('ramda');

var _jquery = require('jquery');

var _jquery2 = _interopRequireDefault(_jquery);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var numbers;

var polarToCart = function polarToCart(x, y, r, angle) {
    return {
        x: x + r * Math.cos(angle),
        y: y + r * Math.sin(angle)
    };
};

var dialArc = function dialArc(x, y, r, percent) {
    var start = polarToCart(x, y, r, -Math.PI / 2);
    var end = polarToCart(x, y, r, -Math.PI / 2 + 2 * Math.PI * (percent / 100));
    var largeArc = percent <= 50 ? '0' : '1';

    return 'M' + start.x + ',' + start.y + ' A' + r + ',' + r + ' 0 ' + largeArc + ' 1 ' + end.x + ' ' + end.y;
};

var drawDial = function drawDial(pct) {
    var output = '<svg class="dial" viewBox="0 0 200 200" width="200" height="200">';
    output += '<circle class="inner" r="90" cx="100" cy="100"></circle>';

    if (+pct === 100) {
        output += '<circle class="outline" r="90" cx="100" cy="100"></circle>';
    } else {
        output += '<path class="outline" d="' + dialArc(100, 100, 90, pct) + '"></path>';
    }
    output += '<path class="outline" d="' + dialArc(100, 100, 90, pct) + '"></path>';
    output += '</svg>';

    return output;
};

var toNumber = function toNumber(value) {
    return +value;
};

var addNormalValue = (0, _ramda.curry)(function (maxval, obj) {
    return (0, _ramda.merge)(obj, {
        normal: +obj.value / maxval * 150
    });
});

var log = function log(value) {
    console.log(value);
    return value;
};

var normalise = function normalise(data) {
    var maxValue = (0, _ramda.compose)((0, _ramda.reduce)(_ramda.max, 0), (0, _ramda.map)(toNumber), (0, _ramda.pluck)('value'))(data);
    return (0, _ramda.map)(addNormalValue(maxValue))(data);
};

var createBar = function createBar(item, index) {
    var output = '<g class="bar-container">';
    output += '<text class="job" y="' + index * 40 + '">' + item.job + '</text>';
    output += '<rect class="bar" width="' + item.normal + '" y="' + index * 40 + '"></rect>';
    output += '<text class="value" x="' + (item.normal + 165) + '" y="' + index * 40 + '">' + item.value + '</text>';
    output += '</g>';

    return output;
};

var drawChart = function drawChart(user) {
    var data = (0, _ramda.compose)(normalise, (0, _ramda.project)(['job', 'value']), (0, _ramda.filter)((0, _ramda.propEq)('type', user.chartToShow === 'technician' ? '1' : '2')))(user.benchmarks);
    var output = '<svg viewBox="0 0 340 200" width="340" height="200">';

    output += (0, _ramda.compose)((0, _ramda.join)(''), (0, _ramda.addIndex)(_ramda.map)(createBar))(data);

    output += '</svg>';

    return output;
};

var countDown = function countDown(elem) {
    var value = +elem.innerText;
    var currentValue = value + 20;

    var interval = setInterval(function () {
        if (currentValue === value) {
            clearInterval(interval);
        } else {
            currentValue -= 1;
        }

        elem.innerText = currentValue;
    }, 50);
};

var dashboard = exports.dashboard = function dashboard(notifier, language, api) {
    return {
        template: _dashboard2.default,
        data: function data() {
            var user = api.getUser();

            return {
                welcomeMessageTopLabel: language.welcomeMessageTop,
                welcomeMessageBottomLabel: language.welcomeMessageBottom,
                globalRankingLabel: language.globalRanking,
                regionalRankingLabel: language.regionalRanking,
                peerGroupRankingLabel: language.peerGroupRanking,
                iltLabel: language.ilt,
                welcomeMessageResult: this.getResultMessage(user.overallResult),
                overallResult: user.overallResult === null ? '0' : user.overallResult,
                status: user.status,
                globalRankingResult: user.globalRanking === '0' || user.globalRanking === '' ? '-' : user.globalRanking,
                regionalRankingResult: user.regionalRanking === '0' || user.regionalRanking === '' ? '-' : user.regionalRanking,
                peerGroupRankingResult: user.peerGroupRanking === '0' || user.peerGroupRanking === '' ? '-' : user.peerGroupRanking,
                iltSuggestion: user.iltSuggestion,
                name: user.firstName,
                showSales: user.showSales === 'yes',
                showStats: window.innerWidth > 600,
                showMessage: user.dashboardMessage === 'yes',
                showSalesResultMessage: user.showSales === 'yes' && user.globalRanking === '0',
                salesResultMessage: language.salesResultMessage,
                showChart: user.chartToShow !== 'no',
                chartMessage: language.chartMessage,
                fauxDashboardMessage: language.fauxDashboardMessage
            };
        },
        created: function created() {
            notifier.addListener('app:show-dashboard', this.countdownNumbers);
        },
        attached: function attached() {
            var svg = (0, _jquery2.default)('.meter');
            svg.html(drawDial(this.overallResult));

            numbers = (0, _jquery2.default)('.sales-result-number');

            var user = api.getUser();

            if (user.chartToShow !== 'no') {
                var chart = (0, _jquery2.default)('.chart-inner');
                chart.html(drawChart(user));
            }
        },
        destroyed: function destroyed() {
            notifier.removeListener('app:show-dashboard', this.countdownNumbers);
        },

        methods: {
            scrollUp: function scrollUp() {
                notifier.emit('app:scroll-to', 'dashboard-container');
            },
            displayStats: function displayStats() {
                this.showStats = true;
                this.$nextTick(function () {
                    return notifier.emit('app:scroll-to', 'stats');
                });
            },
            countdownNumbers: function countdownNumbers() {
                if (this.globalRankingResult !== '-') {
                    (0, _ramda.forEach)(countDown, numbers);
                }
            },
            getResultMessage: function getResultMessage(result) {
                if (result >= 90) {
                    return language.resultExcellent;
                } else if (result >= 80) {
                    return language.resultGreat;
                } else if (result >= 70) {
                    return language.resultGood;
                } else if (result >= 60) {
                    return language.resultOK;
                } else if (result >= 50) {
                    return language.resultFair;
                } else {
                    return language.resultNeedWork;
                }
            }
        }
    };
};
