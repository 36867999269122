import template from '../templates/pages/main.html';
import $ from 'jquery';

var main = undefined,
    dashboard = undefined,
    requestor = undefined;

export const mainPage = function(notifier){
    return {
        template,
        created(){
            notifier.addListener('app:scroll-to', this.scrollTo);
            notifier.addListener('app:show-main', this.showMain);
            notifier.addListener('app:show-dashboard', this.showDashboard);
            notifier.addListener('app:show-requestor', this.showRequestor);
        },
        attached() {
            main = $('#main-container');
            dashboard = $('#dashboard-container');
            requestor = $('#requestor-container');
        },
        destoyed(){
            notifier.removeListener('app:scroll-to', this.scrollTo);
            notifier.removeListener('app:show-main', this.showMain);
            notifier.removeListener('app:show-dashboard', this.showDashboard);
            notifier.removeListener('app:show-requestor', this.showRequestor);
        },
        methods: {
            scrollTo(id){
              var page = $('html, body');

              page.on("scroll mousedown wheel DOMMouseScroll mousewheel keyup touchmove", () => {
                   page.stop();
                   page.off("scroll mousedown wheel DOMMouseScroll mousewheel keyup touchmove");
               });

              this.$nextTick(() => {
                  var offset = id ? $('#' + id).offset().top - 70 : -70;
                  //console.log('offset=' + offset, id);

                  page.animate({ scrollTop: offset }, 1000, () => {
                      page.off("scroll mousedown wheel DOMMouseScroll mousewheel keyup touchmove");
                  });
              });
            },
            showMain() {
                main.show();
                dashboard.hide();
                requestor.hide();
            },
            showDashboard() {
                main.hide();
                dashboard.show();
                requestor.hide();
                notifier.emit('app:scroll-to');
            },
            showRequestor() {
                main.hide();
                dashboard.hide();
                requestor.show();
                notifier.emit('app:scroll-to');
            }
        }
    };
};
