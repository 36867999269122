var _requestor = require('../templates/components/requestor.html');

var _requestor2 = _interopRequireDefault(_requestor);

var _jquery = require('jquery');

var _jquery2 = _interopRequireDefault(_jquery);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var requestor = exports.requestor = function requestor(notifier, language, api) {
    return {
        template: _requestor2.default,
        data: function data() {
            var user = api.getUser();

            return {
                request: '',
                requestorTitle: language.requestorTitle,
                requestPlaceholder: language.requestorPlaceholder,
                requestError: '',
                requestSubmitLabel: language.requestorSubmit,
                requestMessage: '',
                submitDisabled: false
            };
        },

        methods: {
            submitRequest: function submitRequest() {
                var _this = this;

                this.requestError = this.request === '' ? language.requestorError : '';

                if (this.request !== '' && !this.submitDisabled) {
                    this.submitDisabled = true;

                    api.contentRequest({
                        request: this.request
                    }).fork(api.logError, function (result) {
                        _this.submitDisabled = false;

                        if (result.valid) {
                            _this.requestMessage = language.requestorMessageSuccess;
                        } else {
                            _this.requestMessage = language.requestorMessageFailure;
                        }
                    });
                }
            }
        }
    };
};
