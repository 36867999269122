import template from '../templates/components/subcategories.html';
import Swiper from 'swiper';
import $ from 'jquery';

const swiperOptions = {
  slidesPerView: 'auto',
  nextButton: '.swiper-button-next',
  prevButton: '.swiper-button-prev'
};

var menuOpen = false;
var swiper;
var subCatContainer;
var icons;

export const subcategories = function(notifier, language, api) {
  return {
    template,
    data() {
        return {
          currentCategory: undefined,
          selectedCategoryId: 0,
          categories: [],
          subCategories: [],
          title: language.continueJourney,
          subTitle: language.chooseCategory
        };
      },
      created() {
        notifier.addListener('categories:select', this.selectSubCategory);
        notifier.addListener('app:menu-state', this.changeMenuState);
        notifier.addListener('app:initial-data', this.setCategories);
        notifier.addListener('app:reset', this.reset);
      },
      attached() {
        this.$nextTick(() => swiper = new Swiper('#sub-categories-list-swiper', swiperOptions));
        subCatContainer = $('#sub-categories-list');
      },
      destroyed() {
        notifier.removeListener('categories:select', this.selectSubCategory);
        notifier.removeListener('app:menu-state', this.changeMenuState);
        notifier.removeListener('app:initial-data', this.setCategories);
        notifier.removeListener('app:reset', this.reset);
      },
      methods: {
          reset() {
            this.categories = [];
            this.subCategories = [];
            this.currentCategory = undefined;
            this.selectedCategoryId = 0;
          },
          setCategories(data) {
            this.categories = data[0];
          },
          selectCategory(category) {
            if (menuOpen) {
              return;
            }

            notifier.emit('subcategories:select', category);
            this.selectedCategoryId = category.id;
          },
          selectSubCategory(category) {
            if (menuOpen || category.subCategories.length === 0) {
              return;
            }

            this.currentCategory = category;

            if (category.subCategories.length > 0) {
              this.showSubCategories(category);
            }
          },
          upLevel(category) {
            if (menuOpen || category.parent < 4) {
              return;
            }

            this.selectedCategoryId = this.currentCategory.id;
            this.currentCategory = api.getCategory(this.currentCategory.parent, this.categories);
            this.showSubCategories(this.currentCategory);
          },
          showSubCategories(category) {
            if (icons) {
              icons.hide();
            }

            subCatContainer.fadeOut(400, () => {
              this.subCategories = category.subCategories;
              swiper.slideTo(0); //TODO: slide to correct parent
              this.subTitle = (this.currentCategory.parent > 0) ? language.refineCategory : language.chooseCategory;

              subCatContainer.fadeIn(500, () => {
                this.$nextTick(() => {
                  swiper.update();
                  notifier.emit('app:scroll-to', 'sub-categories');
                  icons = $('.sub-categories-list-item-icons-left, .sub-categories-list-item-icons-right');
                  icons.show();

                });
              });
            });
          },
          changeMenuState(state) {
            menuOpen = state;
          }
      }
  };
};
