import template from '../templates/components/menu.html';
import $ from 'jquery';

var showArea = '';
var showPick = '';
var showSearch = '';

export const menu = function(notifier, language, api){
    return {
        template,
        created() {
            notifier.addListener('app:menu-state', this.onMenuChanged);
            notifier.addListener('app:signed-in', this.updateMenu);
        },
        destroyed() {
            notifier.removeListener('app:menu-state', this.onMenuChanged);
            notifier.removeListener('app:signed-in', this.updateMenu);
        },
        data(){
            return {
                searchText: '',
                searchPlaceholder: language.search,
                recentItem: language.recent,
                featuredItem: language.featured,
                popularItem: language.popular,
                //dashboardItem: language.dashboard,
                //requestorItem: language.requestor,
                //requestorShown: false,
                signOutItem: language.signOut,
                languageCode: language.code
            };
        },
        methods: {
            updateMenu() {
                //var user = api.getUser();
                //this.requestorShown = (user.contentRequestor === 'yes');
            },
            doSearch(){
                if (this.searchText !== ''){
                  notifier.emit('app:toggle-menu');
                  showSearch = this.searchText;
                  showArea = 'main';
                  showPick = '';
                  this.searchText = '';
                }

                $('#search-box').blur();
            },
            showRecent() {
              notifier.emit('app:toggle-menu');
              showArea = 'main';
              showPick = 'recent';
            },
            showFeatured() {
              notifier.emit('app:toggle-menu');
              showArea = 'main';
              showPick = 'featured';
            },
            showPopular() {
              notifier.emit('app:toggle-menu');
              showArea = 'main';
              showPick = 'popular';
            },
            showCnVersion() {
                window.location = 'https://knowledgebank.rolls-roycemotorcars.cn';
            },
            showEnVersion() {
                window.location = 'https://knowledgebank.rolls-roycemotorcars.com';
            },
            /*showDashboard: function showDashboard() {
                notifier.emit('app:toggle-menu');
                showArea = 'dashboard';
            },
            showRequestor: function showRequestor() {
                notifier.emit('app:toggle-menu');
                showArea = 'requestor';
            },*/
            signOut() {
                notifier.emit('app:toggle-menu');
                notifier.emit('app:sign-out');
            },
            onMenuChanged(open) {
                if (open === false) {
                    if (showArea !== '') {
                        notifier.emit('app:show-' + showArea);
                        showArea = '';
                    }

                    if (showPick !== '') {
                        this.$nextTick(function () {
                            notifier.emit('content:pick', showPick);
                            showPick = '';
                        });
                    }

                    if (showSearch !== '') {
                        notifier.emit('content:search', showSearch);
                        showSearch = '';
                    }
                }
            }
        }
    };
};
