import Vue from 'vue';
import VueRouter from 'router';
import {
  contains,
  mergeAll
} from 'ramda';
import EventEmitter from 'event.emitter';

//import {config} from './config/config';

import {
  appView
} from './pages/app-view';
import {
  mainPage
} from './pages/main-page';
import {
  signInPage
} from './pages/signin-page';
import {
  firstTimePage
} from './pages/firsttime-page';
import {
  changePasswordPage
} from './pages/changepassword-page';
import {
  resetPasswordPage
} from './pages/resetpassword-page';

import {
  header
} from './components/header';
import {
  footer
} from './components/footer';
import {
  menu
} from './components/menu';
import {
  categories
} from './components/categories';
import {
  subcategories
} from './components/subcategories';
import {
  hero
} from './components/hero';
import {
  content
} from './components/content';
import {
  dashboard
} from './components/dashboard';
import {
  requestor
} from './components/requestor';


import {
  apiService
} from './services/apiservice';
import {
  getStrings
} from './language/language';

//enable debugging
Vue.config.debug = true;

const notifier = new EventEmitter();
//console.log(window.location.href);
const version = contains('.cn', window.location.href) ? 'cn' : 'en';
const language = getStrings(version);

apiService.setLanguage(language);

var signedIn = false;

const initialiseData = () => apiService.initialiseData().fork(
  apiService.logError,
  (data) => {
    router.go('/main');
    setTimeout(() => notifier.emit('app:initial-data', data));
  }
);

notifier.addListener('app:sign-in', (details) => {
  signedIn = true;

  apiService.setUser(mergeAll([details.user, {
    benchmarks: details.benchmarks
  }]));

  const token = {
    token: details.token
  };

  apiService.setToken(token);

  apiService.setLocal('tokenv2', token).fork(apiService.logError, () => {
    if (details.user.changePassword === '1') {
      router.go('/changepassword');
    } else {
      notifier.emit('app:enable-menu', true);
      initialiseData();
      notifier.emit('app:scroll-to');
      notifier.emit('app:signed-in');
    }
  });
});

notifier.addListener('app:reset', () => {
  notifier.emit('app:scroll-to');
});

notifier.addListener('app:first-time', () => {
  router.go('/firsttime');
});

notifier.addListener('app:reset-password', () => {
  router.go('/resetpassword');
});

notifier.addListener('app:return-sign-in', () => {
  router.go('/signin');
});

notifier.addListener('app:return-change-password', () => {
  notifier.emit('app:enable-menu', true);
  initialiseData();
  notifier.emit('app:scroll-to');
  notifier.emit('app:signed-in');
});

notifier.addListener('app:change-password', () => {
  router.go('/changepassword');
});

notifier.addListener('app:sign-out', () => {
  apiService.removeLocal('tokenv2').fork(
    apiService.logError, () => {
      signedIn = false;
      router.go('/signin');
      notifier.emit('app:scroll-to');
      notifier.emit('app:enable-menu', false);
      notifier.emit('app:signed-out');
    }
  );
});

//init main app
const app = Vue.extend(appView(notifier));

//register components
Vue.use(VueRouter);

Vue.component('pageheader', header(notifier, language));
Vue.component('pagefooter', footer(notifier, language));
Vue.component('dashboard', content(notifier, language, apiService));
Vue.component('requestor', content(notifier, language, apiService));
Vue.component('menu', menu(notifier, language, apiService));
Vue.component('content', content(notifier, language, apiService));
Vue.component('categories', categories(notifier, language, apiService));
Vue.component('subcategories', subcategories(notifier, language, apiService));
Vue.component('hero', hero(notifier, language, apiService));

//init and start routing
const router = new VueRouter({
  hashbang: false
});

//stop guest access to anything except signin and firsttime
router.beforeEach((transition) => {
  if (transition.to.path === '/firsttime' || transition.to.path === '/resetpassword') {
    transition.next();
  } else if ((!signedIn && transition.to.path !== '/signin') || transition.to.path === '/') {
    transition.abort();
  } else {
    transition.next();
  }
});

router.map({
  '/main': {
    component: Vue.extend(mainPage(notifier))
  },
  '/signin': {
    component: Vue.extend(signInPage(notifier, language, apiService))
  },
  '/firsttime': {
    component: Vue.extend(firstTimePage(notifier, language, apiService))
  },
  '/changepassword': {
    component: Vue.extend(changePasswordPage(notifier, language, apiService))
  },
  '/resetpassword': {
    component: Vue.extend(resetPasswordPage(notifier, language, apiService))
  }
});

router.start(app, '#app');

apiService.validateUser.fork(
  () => router.go('/firsttime'),
  (details) => {
    if (details.valid) {
      notifier.emit('app:sign-in', details);
    } else {
      router.go('/signin');
    }
  }
);

//debug helper
window.app = app;