import $ from 'jquery';
import {compose, filter, prop, map, curry, reduce, commute, take, find, chain, merge, head, split, reject, sortBy, mergeAll} from 'ramda';
import Task from 'data.task';
import {config} from '../config/config';

var currentToken;
var currentUser;
var currentLanguage;

const log = (obj) => {
    console.log(obj);
    return obj;
};

const apiCall = curry((endpoint, options) => new Task((rej, res) => $.ajax({type: 'POST',url: './api/' + endpoint, data: options}).then(res).fail(rej)));

const getLocal = (key) => new Task((rej, res) => {
  var data = localStorage.getItem(key);
  if (!data){
    rej();
  } else {
    res(JSON.parse(data));
  }
});

const removeLocal = (key) => new Task((rej, res) => {
  try{
    localStorage.removeItem(key);
    res();
  } catch(err){
    rej(err);
  }
});

const setLocal = (key, data) => new Task((rej, res) => {
  try{
    localStorage.setItem(key, JSON.stringify(data));
    res();
  } catch(err){
    rej(err);
  }
});

const projectHumanTime = (code, timestamp) => {

    const units = [
        {name: 'now', nameCN: '现在', limit: 5, seconds: undefined},
        {name: 'second', nameCN: '秒钟', limit: 60, seconds: 1 },
        {name: 'minute', nameCN: '分钟', limit: 3600, seconds: 60 },
        {name: 'hour', nameCN: '小时', limit: 86400, seconds: 3600  },
        {name: 'day', nameCN: '天', limit: 604800, seconds: 86400 },
        {name: 'week', nameCN: '星期', limit: 2629743, seconds: 604800 },
        {name: 'month', nameCN: '个月', limit: 31556926, seconds: 2629743 },
        {name: 'year', nameCN: '年', limit: Infinity, seconds: 31556926 }
    ];

    const diff = (new Date() - new Date(timestamp*1000)) / 1000;

    const isAtLimit = curry((diff, acc, unit) => {
        return (diff > acc.limit && diff <= unit.limit) ? unit : acc;
    });

    const projectTime = curry((code, diff, unit) => {
        const quantity = Math.floor(diff / unit.seconds);
        return (code === 'EN') ? quantity + ' ' + unit.name + (quantity>1 ? 's' : '') + ' ago' :  quantity + ' ' + (quantity>1 ? '几' : '一') + unit.nameCN + '之前';
    });

    return compose(projectTime(code, diff), reduce(isAtLimit(diff), {limit: 0}))(units);
};

const projectCategory = (category) => {
    return {
        id: category.categoryID,
        image: category.categoryImage,
        name: category['categoryName' + currentLanguage.code],
        parent: category.categoryParent,
        displayOrder: category.displayOrder,
        subCategories: []
    };
};

const projectVideo = (video) => {
    return {
        id: video.videoID,
        embed: video.embedCode,
        embedUrl: 'https://view.vzaar.com/' + video.embedCode + '/player',
        posterUrl: 'https://view.vzaar.com/' + video.embedCode + '/image',
        videoUrl: 'https://view.vzaar.com/' + video.embedCode + '/video',
        thumbnailUrl: 'https://view.vzaar.com/' + video.embedCode + '/thumb',
        datePosted: video.datePosted,
        timeAgo: projectHumanTime(currentLanguage.code, video.datePosted),
        relatedVideos: video.relatedVideos ? map(projectVideo)(video.relatedVideos) : [],
        relatedDocuments: video.relatedDocuments ? map(projectDocument)(video.relatedDocuments) : [],
        name: video['title' + currentLanguage.code] ? video['title' + currentLanguage.code] : video.titleEN,
        desc: video['description' + currentLanguage.code] ? video['description' + currentLanguage.code] : video.descriptionEN,
        viewCount: +video.viewCount,
        played: false
    };
};

const createFolderName = (filename) => {
  return compose(head, split('.'))(filename) + '/';
};

const projectDocument = (doc) => {
    return {
        id: doc.documentID,
        filePath: config['serverUrl' + currentLanguage.code] + '/documents/' + createFolderName(doc.fileName),
        datePosted: doc.datePosted,
        language: doc.language,
        timeAgo: projectHumanTime(currentLanguage.code, doc.datePosted),
        name: doc['title' + currentLanguage.code] ? doc['title' + currentLanguage.code] : doc.titleEN,
        desc: doc['description' + currentLanguage.code] ? doc['description' + currentLanguage.code] : doc.descriptionEN,
        relatedDocuments: doc.relatedDocuments ? map(projectDocument)(doc.relatedDocuments) : [],
        translatedDocuments: doc.translatedDocuments ? map(projectDocument)(doc.translatedDocuments) : [],
        viewCount: +doc.viewCount,
        opened: false
    };
};

const filterItem = (item) => {
  if (currentUser.region === 'INT'){
    return true;
  } else {
    switch(currentUser.region){
      case 'ROW':
        return (item.location.indexOf('ROW') > -1);
      case 'NA':
        return (item.location.indexOf('NA') > -1);
      case 'CN':
        return (item.location.indexOf('CH') > -1); //incorrect labelling in spreasheet CH not CN
      default:
        return false;
    };
  }
};

const projectContent = (content) => {
    return {
        videos: compose(map(projectVideo), filter(filterItem))(content.videos),
        documents: compose(map(projectDocument), filter(filterItem))(content.documents)
    };
};

const processCategories = compose(map(projectCategory), prop('categories'));

const isCategoryWithParent = curry((parentid, category) => category.parent === parentid);

const projectSubcategories = curry((categories, category) => {
    category.subCategories = compose(sortBy(prop('displayOrder')), filter(isCategoryWithParent(category.id)))(categories);
    return category;
});

const processSubcategories = (categories) => map(projectSubcategories(categories))(categories);

const projectCategories = compose(map(processSubcategories), map(processCategories), apiCall('categories'));

const processVideos = compose(map(projectVideo), prop('videos'));

const projectFeaturedVideos = compose(map(processVideos), apiCall('featuredvideos'));

const isCategory = curry((id, category) => category.id === id);

const getCategory = (id, categories) => find(isCategory(id))(categories);

const getTopCategories = (categories) => filter(isCategoryWithParent('0'))(categories);

const getFeaturedVideos = (count, featuredvideos) => take(count)(featuredvideos);

const getContent = (options) => compose(map(projectContent), apiCall(options.type + 'content'))(mergeAll([options, currentUser, currentToken]));

const incrementViewCount = (id, type) => apiCall('incrementviewcount', merge({itemid: id, itemtype: type}, currentUser));

const logError = (err) => console.error(err.reponseText ? err.responseText : err);

const initialiseData = () => commute(Task.of, [projectCategories(currentToken), projectFeaturedVideos(merge(currentUser, currentToken))]);

const validateUser = compose(chain(apiCall('validateuser')), getLocal)('tokenv2');

const signIn = apiCall('signin');

const sendTemporaryPassword = (options) => apiCall('temporarypassword')(options);

const changePassword = (options) => apiCall('changepassword')(mergeAll([options, currentUser, currentToken]));

const contentRequest = (options) => apiCall('contentrequest')(mergeAll([options, { userid: currentUser.userId }, currentToken]));

const getUser = () => currentUser;
const setUser = (user) => currentUser = user;
const setLanguage = (language) => currentLanguage = language;
const setToken = (token) => currentToken = token;

export const apiService = {
    getUser,
    setUser,
    setLanguage,
    setToken,
    signIn,
    sendTemporaryPassword,
    changePassword,
    validateUser,
    getLocal,
    setLocal,
    removeLocal,
    initialiseData,
    getCategory,
    getTopCategories,
    getContent,
    getFeaturedVideos,
    logError,
    incrementViewCount,
    contentRequest
};
