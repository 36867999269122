import template from '../templates/components/hero.html';
import $ from 'jquery';

var menuOpen = false;
var videoPlayer;

export const hero = function(notifier, language, api){
    return {
        template,
        data() {
            return {
                currentVideo: {
                  posterUrl: '',
                  name: '',
                  desc: ''
                },
                videos: [],
                state: 'poster',
                featuredTitle: language.featured,
                playVideoLabel: language.playVideo,
                showWatermark: (api.getUser().region === 'NA')
            };
        },
        created(){
            notifier.addListener('hero:setvideos', this.setVideos);
            notifier.addListener('app:menu-state', this.changeMenuState);
            notifier.addListener('app:initial-data', this.setVideos);
            notifier.addListener('app:reset', this.reset);
        },
        attached(){
          videoPlayer = $('#hero-video')[0];
        },
        destroyed(){
            notifier.removeListener('hero:setvideos', this.setVideos);
            notifier.removeListener('app:menu-state', this.changeMenuState);
            notifier.removeListener('app:initial-data', this.setVideos);
            notifier.removeListener('app:reset', this.reset);
        },
        methods: {
            reset() {
                this.currentVideo = this.videos[0];
            },
            setVideos(data){
                this.videos = data[1];
                this.currentVideo = this.videos[0];
            },
            setCurrentVideo(video){
                if (menuOpen){
                    return;
                }

                this.currentVideo = video;
                this.state = 'poster';
                videoPlayer.pause();
            },
            playVideo(video){
                if (menuOpen){
                    return;
                }

                //console.log('playing video ' + video.name);
                this.state = 'video';
                videoPlayer.play();
            },
            closeVideo(){
                this.state = 'poster';
                videoPlayer.pause();
            },
            changeMenuState(state){
                menuOpen = state;
            }
        }
    };
};
