import template from '../templates/components/header.html';
import $ from 'jquery';
import {utils} from '../services/utilfunctions';

$(window).scroll(utils.debounce(() => {
    var scroll = $(window).scrollTop();
    var logo = $('#logo');
    if (scroll >= 10) {
        if (!logo.hasClass('header-logo-scale')){
            logo.addClass("header-logo-scale");
        }
    } else {
        if (logo.hasClass('header-logo-scale')){
        logo.removeClass("header-logo-scale");
        }
    }
}, 250));

export const header = function(notifier, language){
    return {
        template,
        data(){
            return {
              isLoading: false,
              isSignedIn: false,
              menuEnabled: false,
              menuTitle: language.menu,
              knowledgeBankTitle: language.knowledgeBank
            };
        },
        created() {
          notifier.addListener('app:signed-in', this.onSignIn);
          notifier.addListener('app:signed-out', this.onSignOut);
          notifier.addListener('app:enable-menu', this.enableMenu);
          notifier.addListener('app:toggle-loading', this.toggleLoading);
        },
        destroyed() {
          notifier.removeListener('app:signed-in', this.onSignIn);
          notifier.removeListener('app:signed-out', this.onSignOut);
          notifier.removeListener('app:enable-menu', this.enableMenu);
          notifier.removeListener('app:toggle-loading', this.toggleLoading);
        },
        methods: {
          onSignIn() {
            this.isSignedIn = true;
          },
          onSignOut() {
            this.isSignedIn = false;
          },
          toggleMenu(){
            notifier.emit('app:toggle-menu');
          },
          enableMenu(enable){
              this.menuEnabled = enable;
          },
          scrollUp(){
            notifier.emit('app:scroll-to', 'content');
          },
          toggleLoading(){
            this.isLoading = !this.isLoading;
          },
          resetApp: function resetApp() {
            notifier.emit('app:reset');
          }
        }
    };
};
