import template from '../templates/pages/firsttime.html';

import {
  test
} from 'ramda';

export const firstTimePage = function(notifier, language, api) {

  const checkEmail = test(/^(?:(?:[\w`~!#$%^&*\-=+;:{}'|,?\/]+(?:(?:\.(?:"(?:\\?[\w`~!#$%^&*\-=+;:{}'|,?\/\.()<>\[\] @]|\\"|\\\\)*"|[\w`~!#$%^&*\-=+;:{}'|,?\/]+))*\.[\w`~!#$%^&*\-=+;:{}'|,?\/]+)?)|(?:"(?:\\?[\w`~!#$%^&*\-=+;:{}'|,?\/\.()<>\[\] @]|\\"|\\\\)+"))@(?:[a-zA-Z\d\-]+(?:\.[a-zA-Z\d\-]+)*|\[\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}\])$/g);

  const imageIndex = Math.floor(Math.random() * 14) + 1;

  return {
    template,
    data() {
      return {
        emailAddress: '',
        emailAddressError: '',
        message: '',
        returnMessage: '',
        imageIndex: imageIndex,
        imageClass: 'signin-image-inner',
        imageSrc: './images/heroimages/Image' + imageIndex + '.jpg',
        knowledgeBankTitle: language.knowledgeBankTwoLines,
        firstTimeTitle: language.firstTimeTitle,
        firstTimeSignedUpQuestion: language.firstTimeSignedUpQuestion,
        emailAddressPlaceholder: language.emailAddressPlaceholder,
        sendTemporaryPasswordLabel: language.sendTemporaryPasswordLabel
      };
    },
    methods: {
      returnSignIn() {
        notifier.emit('app:return-sign-in');
      },
      sendTemporaryPassword() {
        this.emailAddressError = (this.emailAddress === '') ? language.emailAddressError : (!checkEmail(this.emailAddress) ? language.emailAddressNotValidError : '');

        if (this.emailAddressError === '') {
          api.sendTemporaryPassword({
            emailAddress: this.emailAddress
          }).fork(
            api.logError, (email) => {
              if (email.valid) {
                this.message = language.emailSent;
              } else {
                switch (email.errorReason) {
                  case 'notvalid':
                    this.message = language.emailAddressNotValidError;
                    break;

                  case 'notfound':
                  case 'notfirsttime':
                    notifier.emit('app:return-sign-in')
                    break;
                }
              }
            }
          );
        }
      }
    }
  };
};
