export const getStrings = (language) => {
  switch (language) {

    case 'en':
      return {
        code: 'EN',
        userName: 'username',
        password: 'password',
        signIn: 'sign in',
        signOut: 'sign out',
        userNameError: 'We\'ll need a user name',
        passwordError: 'We\'ll need a password',
        signInError: 'Sorry, we couldn\'t sign you in.',
        menu: 'menu',
        knowledgeBank: 'Knowledge Bank',
        knowledgeBankTwoLines: 'Knowledge<br/>Bank',
        search: 'search',
        recent: 'Recent',
        featured: 'Featured',
        popular: 'Popular',
        dashboard: 'Dashboard',
        requestor: 'Content Requestor',
        playVideo: 'play video',
        welcomeMobile: 'Choose<br/>your journey',
        welcome: 'Welcome to the Knowledge Bank',
        welcomeSub: '<p>This resource contains an extensive database of written and visual Rolls-Royce Motor Cars content. It is designed to serve as a quick reference guide for important information. The varied array of videos will also help to keep your knowledge up to speed.</p><p>Need a quick refresher when working on a car? Check out the technical guides. Want to know the detailed benefits of a car you’re selling? Have a look at the feature-by-feature guides.</p><p>Everything has been grouped by topic area and the information you’re looking for is never more than a few clicks away. To begin, please choose the category below that corresponds with your area of the business.</p>',
        continueJourney: 'Continue your journey',
        chooseCategory: 'Choose your Category',
        refineCategory: 'Refine your Category',
        discover: 'Discover & explore...',
        videos: 'Videos',
        documents: 'Documents',
        noVideos: 'No Videos Found',
        noDocuments: 'No Documents Found',
        videosFromCategory: 'Videos from Category',
        documentsFromCategory: 'Documents from Category',
        videosFromSearch: 'Videos from Search',
        documentsFromSearch: 'Documents from Search',
        view: 'view',
        views: 'views',
        firstTimeQuestion: 'First time here?',
        havingTroubleQuestion: 'Having trouble signing in?',
        supportStatement: 'Please contact dealer.consulting@rolls-roycemotorcars.com if you need further support',
        oldPasswordError: 'We\'ll need your old password',
        newPasswordError: 'We\'ll need your new password',
        repeatNewPasswordError: 'We\'ll need you to repeat your new password',
        passwordMatchError: 'The new passwords don\'t match',
        changePasswordError: 'Sorry but we couldn\'t change the password',
        oldPassword: 'old password',
        newPassword: 'new password',
        repeatNewPassword: 'repeat new password',
        changePasswordTitle: 'change your password',
        changePasswordLabel: 'change password',
        resetPasswordTitle: 'reset your password',
        resetPasswordText: 'Enter your email address and we\'ll send you a new temporary password',
        resetPasswordLabel: 'reset password',
        signInTitle: 'Please sign in',
        firstTimeTitle: 'Set up your user account by providing your email address and we\'ll send you a temporary password',
        firstTimeSignedUpQuestion: 'Already signed up?',
        emailAddressPlaceholder: 'email address',
        sendTemporaryPasswordLabel: 'Send a temporary password',
        emailAddressError: 'We\'ll need an email address',
        emailAddressNotValidError: 'We\'ll need a valid email address',
        emailNotFoundError: 'The email address is not registered',
        emailSent: 'An email containing your temporary password has been sent.  Follow the instructions in the email.<br/><br/>If you encounter any problems with your registration please feel free to contact the Dealer Training & Consulting team on:<br/><br/>dealer.consulting@rolls-roycemotorcars.com',
        returnMessage: 'Return to Sign In Page',
        welcomeMessageTop: 'Welcome back',
        welcomeMessageBottom: 'Things are looking',
        globalRanking: 'Global Ranking',
        regionalRanking: 'Regional Ranking',
        peerGroupRanking: 'Peer Group Ranking',
        ilt: 'Have you considered?',
        chartMessage: 'How does your progress compare to the rest of our dealer network?<br/><br/>Here’s the level of qualification attained by your peers',
        resultExcellent: 'Excellent',
        resultGreat: 'Great',
        resultGood: 'Pretty Good',
        resultOK: 'Okay',
        resultFair: 'Fair',
        resultNeedWork: 'In Need of Work',
        salesResultMessage: 'Message about zero scores',
        requestorTitle: 'Request New Content',
        requestorPlaceholder: 'e.g. Requestor placeholder',
        requestorError: 'We\'ll need something to request',
        requestorSubmit: 'Submit your requests',
        requestorMessageSuccess: 'Thank you. Your requests have been submitted.',
        requestorMessageFailure: 'Unfortunately we couldn\'t submit your request. If this continues to happen, please contact ...',
        fauxDashboardMessage: 'Message about dashboard for internal users'
      };

    case 'cn':

      return {
        code: 'CN',
        userName: '用户名',
        password: '密码',
        signIn: '登录',
        signOut: '退出',
        userNameError: '您需要输入用户名',
        passwordError: '您需要输入密码',
        signInError: '抱歉，您无法登录。',
        menu: '菜单',
        knowledgeBank: '知识库',
        knowledgeBankTwoLines: '知识库',
        search: '搜索',
        recent: '最近',
        featured: '配置',
        popular: '备受欢迎',
        dashboard: 'dashboard CN',
        requestor: 'requestor CN',
        playVideo: '播放视频',
        welcomeMobile: '选择您的旅程',
        welcome: '欢迎访问知识库',
        welcomeSub: '本资源库集成了一个包含有关劳斯莱斯汽车的大量全面、详实的文本和视频资料的数据库。我们设计此数据库的目的，是为了方便用户快速查阅重要信息。数据库中有内置了大量的视频资料，能帮助您及时获得最新的知识和信息。在车上工作时，需要快速地查询信息？试试技术指南吧。想了解您正在销售的车型具体有哪些优点？翻阅一下配置指南吧。所有资料均按照专题分门别类存放，只需点击几下鼠标，即可找到所需信息。开始前，请先选择与您的业务领域相匹配的类别。',
        continueJourney: '继续您的旅程',
        chooseCategory: '选择类别',
        refineCategory: '进一步选择类别',
        discover: '探索 & 发现...',
        videos: '视频',
        documents: '文件',
        noVideos: '找不到相关视频',
        noDocuments: '找不到相关文件',
        videosFromCategory: '按类别查找视频',
        documentsFromCategory: '按类别查找文件',
        videosFromSearch: '搜索视频',
        documentsFromSearch: '搜索文件',
        view: '看法',
        views: '看法',
        firstTimeQuestion: '第一次来?',
        havingTroubleQuestion: '无法登录?',
        supportStatement: '如需更多帮助请联系dealer.consulting@rolls-roycemotorcars.com',
        oldPasswordError: '请输入旧密码',
        newPasswordError: '请输入新密码',
        repeatNewPasswordError: '请重新输入新密码',
        passwordMatchError: '新密码不匹配',
        changePasswordError: '对不起，无法更改密码',
        oldPassword: '旧密码',
        newPassword: '新密码',
        repeatNewPassword: '重新输入新密码',
        changePasswordTitle: '更改您的密码',
        changePasswordLabel: '更改密码',
        resetPasswordTitle: '重置密码',
        resetPasswordText: '请输入您的邮箱地址，新的临时密码会发送给您',
        resetPasswordLabel: '重置密码',
        signInTitle: '请登录',
        firstTimeTitle: '请提供您的邮箱地址，我们会发送临时密码以便于您建立账户',
        firstTimeSignedUpQuestion: '已经注册完毕？',
        emailAddressPlaceholder: '邮箱地址',
        sendTemporaryPasswordLabel: '发送临时密码',
        emailAddressError: '请填写邮箱地址',
        emailAddressNotValidError: '请填入有效的邮箱地址',
        emailNotFoundError: '此邮箱未被注册过',
        emailSent: '临时密码已通过邮件发送，请根据邮件中的指示进行操作.<br/><br/>如在注册过程中遇到任何问题，请咨询：<br/><br/>dealer.consulting@rolls-roycemotorcars.com',
        returnMessage: '回到登录页面',
        welcomeMessageTop: '欢迎回来',
        welcomeMessageBottom: 'Things are looking CN',
        globalRanking: 'Global Ranking CN',
        regionalRanking: 'Regional Ranking CN',
        peerGroupRanking: 'Peer Group Ranking CN',
        ilt: 'Have you considered? CN',
        chartMessage: 'How does your progress compare to the rest of our dealer network?<br/><br/>Here’s the level of qualification attained by your peers CN',
        resultExcellent: 'Excellent CN',
        resultGreat: 'Great CN',
        resultGood: 'Pretty Good CN',
        resultOK: 'Okay CN',
        resultFair: 'Fair CN',
        resultNeedWork: 'In Need of Work CN',
        salesResultMessage: 'Sales result Message CN',
        requestorTitle: 'Request New Content CN',
        requestorPlaceholder: 'e.g. Requestor placeholder CN',
        requestorError: 'We\'ll need something to request CN',
        requestorSubmit: 'Submit your requests CN',
        requestorMessageSuccess: 'Thank you. Your requests have been submitted. CN',
        requestorMessageFailure: 'Unfortunately we couldn\'t submit your request. CN',
        fauxDashboardMessage: 'Message about dashboard for internal users CN'};
  }
}
