import template from '../templates/app.html';
import $ from 'jquery';

export const appView = function(notifier) {
  return {
    template,
    data() {
      return {
        menuOpen: false,
        scrollerTop: '0',
        headerPosition: 'fixed'
      }
    },
    created() {
      notifier.addListener('app:toggle-menu', this.openMenu);
      notifier.addListener('app:open-pdf', this.openPdf);
    },
    attached(){
      const transformer = $('.transformer');
      transformer.on('transitionend', () => notifier.emit('app:menu-state', this.menuOpen));
    },
    destroyed() {
      notifier.removeListener('app:toggle-menu', this.openMenu);
      notifier.removeListener('app:open-pdf', this.openPdf);
    },
    methods: {
      openMenu() {
        if (this.headerPosition === 'fixed') {
          this.scrollerTop = $(window).scrollTop() + 'px';
          document.body.style.overflow = 'hidden';
          this.headerPosition = 'absolute';
          document.ontouchmove = function(event) {
            event.preventDefault();
          }
        } else {
          window.setTimeout(this.menuClosed, 500);
        }

        this.menuOpen = !this.menuOpen;
      },
      menuClosed() {
        this.scrollerTop = '0';
        document.body.style.overflow = 'auto';

        this.headerPosition = 'fixed';
        document.ontouchmove = undefined;
      }
    }
  };
};
