import template from '../templates/pages/changepassword.html';

export const changePasswordPage = function(notifier, language, api) {
  return {
    template,
    data() {
        return {
          oldPassword: '',
          newPassword: '',
          repeatNewPassword: '',
          oldPasswordError: '',
          newPasswordError: '',
          repeatNewPasswordError: '',
          message: '',
          knowledgeBankTitle: language.knowledgeBankTwoLines,
          oldPasswordPlaceholder: language.oldPassword,
          newPasswordPlaceholder: language.newPassword,
          repeatNewPasswordPlaceholder: language.repeatNewPassword,
          changePasswordTitle: language.changePasswordTitle,
          changePasswordLabel: language.changePasswordLabel
        };
      },
      methods: {
        changePassword() {
          this.oldPasswordError = (this.oldPassword === '') ? language.oldPasswordError : '';
          this.newPasswordError = (this.newPassword === '') ? language.newPasswordError : '';
          this.repeatNewPasswordError = (this.repeatNewPassword === '') ? language.repeatNewPasswordError : (this.newPassword !== this.repeatNewPassword ? language.passwordMatchError : '');

          if (this.oldPassword !== '' && this.newPassword !== '' && this.repeatNewPassword !== '' && (this.newPassword === this.repeatNewPassword)) {
            api.changePassword({
              oldPassword: this.oldPassword,
              newPassword: this.newPassword,
              repeatNewPassword: this.repeatNewPassword
            }).fork(
              api.logError, (details) => {
                if (details.valid){
                  notifier.emit('app:return-change-password');
                } else {
                  this.message = language.changePasswordError;
                  this.oldPassword = '';
                  this.newPassword = '';
                  this.repeatNewPassword = '';
                }
              }
            );
          }
        }
      }
  };
};
